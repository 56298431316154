<template lang="pug">
.solial
  a.solial-item(
    href='https://www.linkedin.com/in/ian-hurdle-69259456/',
    target='_blank',
    rel='noopener'
  ): SvgComponent(
    name='li'
  )
  a.solial-item(href='https://www.instagram.com/caribb.ian', target='_blank', rel='noopener'): SvgComponent(
    name='ig'
  )
  a.solial-item(href='https://www.tiktok.com/@sellingturksandcaicos', target='_blank', rel='noopener'): SvgComponent(
    name='tiktok'
  )
  a.solial-item(href='mailto:ian.hurdle@theagencyre.com'): SvgComponent(name='email')
</template>

<script>
  export default {
    components: {
      SvgComponent: () => import('@/components/SvgComponent'),
    },
  };
</script>

<style lang="scss"></style>
